
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
    d('@lottiefiles/lottie-player', [], function() { return require('@lottiefiles/lottie-player'); });
    d('@sentry/browser', [], function() { return require('@sentry/browser'); });
    d('@sentry/utils', [], function() { return require('@sentry/utils'); });
    d('bootstrap', [], function() { return require('bootstrap'); });
    d('chart.js', [], function() { return require('chart.js'); });
    d('dompurify', [], function() { return require('dompurify'); });
    d('focus-trap', [], function() { return require('focus-trap'); });
    d('moment', [], function() { return require('moment'); });
    d('rsvp', [], function() { return require('rsvp'); });
    d('showdown', [], function() { return require('showdown'); });
    d('tooltip.js', [], function() { return require('tooltip.js'); });
    d('_eai_dyn_@sentry/tracing', [], function() { return import('@sentry/tracing'); });
})();
